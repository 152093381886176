import "./App.css"
import Header from "./components/common/header/Header"
import {
  BrowserRouter,
  Routes, // instead of "Switch"
  Route,
} from "react-router-dom";
import About from "./components/about/About"
import CourseHome from "./components/allcourses/CourseHome"
import Team from "./components/team/Team"
import Pricing from "./components/pricing/Pricing"
import Blog from "./components/blog/Blog"
import Contact from "./components/contact/Contact"
import Footer from "./components/common/footer/Footer"
import Home from "./components/home/Home"
import Billavas from "./components/about/Billavas";
import MulkyYellapaSuvarna from "./components/about/MulkyYellapaSuvarna";
import KavoorSundaryPoojary from "./components/about/KavoorSundaryPoojary";
import NageshKoraggapaa from "./components/about/NageshKoraggapaa";
import MSHEKARPUJARI from "./components/about/MSHEKARPUJARI";
import MBKuckian from "./components/about/MBKuckian";
function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/courses' element={<CourseHome />} />
          <Route exact path='/team' element={<Team />} />
          <Route exact path='/pricing' element={<Pricing />} />
          <Route exact path='/journal' element={<Blog />} />
          <Route exact path='/contact' element={<Contact />} /> 
          <Route exact path='/Billavas' element={<Billavas />} /> 
          <Route exact path='/MulkyYellapaSuvarna' element={<MulkyYellapaSuvarna/>} />
          <Route exact path='/KavoorSundaryPoojary' element={<KavoorSundaryPoojary />} />
          <Route exact path='/NageshKoraggappa' element={<NageshKoraggapaa />} />
          <Route exact path="/MshekarPujari" element={<MSHEKARPUJARI />} />
          <Route exact path="/MBKuckian" element={<MBKuckian />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </>
  )
}

export default App
